.App {
	display: flex;
	flex-direction: row;
	height: 100%;
	&__body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex-grow: 9999;
		text-align: center;
		justify-self: center;
	}
}
