html,
body {
	height: 100%;
}

* {
	margin: 0;
	padding: 0;
	font-family: 'Montserrat', sans-serif;
}

body {
	background-color: #303030;
	box-shadow: inset 0 0 64px #0f0f0f;
	box-sizing: border-box;
}

#root {
	height: 100%;
}
