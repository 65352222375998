.Navigation {
  position: absolute;
  padding: 20px;
  width: 400px;
  top: 0;
  bottom: 0;

  .Hamburger {
    fill: white;
    &:hover {
      fill: gray;
    }
  }

  .Menu {
    top: 0;
    bottom: 0;
    position: absolute;
    left: -500px;
    transition: all 0.5s;
    padding-top: 80px;
    padding-left: 20px;
    padding-right: 100px;

    &.extended {
      left: 0;
      background-color: rgba(31, 31, 31, 0.7);
    }

    &:not(.extended) {
      transform: translate3d(-100%, 0, 0);
    }

    & > * {
      font-size: 40px;
      display: block;
      text-decoration: none;
      color: white;
      &.active {
        color: wheat;
        &:hover {
          color: darken(wheat, 50);
        }
      }
      &:hover {
        color: gray;
      }
    }
  }
}
